



























































































































import { Component, Vue } from "vue-property-decorator";
import { appointmentList } from "@/apis";
import { parseTime } from "@/utils";
//import Edit from "@/views/ActionCenter/ActivityManagement/module/Edit.vue";
@Component({
  components: {},
  filters: {
    parseTimeFilter(time: any, str: string) {
      if (time) {
        return parseTime(time, str);
      } else {
        return "-";
      }
    },
  },
})
export default class AppointmentList extends Vue {
  tableData: Array<any> = [];
  formList = {
    start_createdate: "",
    end_createdate: "",
    patient_name: "",
    appointment_state: "",
  } as any;
  orderState = {
    "101": "待审核",
    "102": "待程控",
    "108": "程控当天",
    "104": "已完成",
    "109": "已完成",
    103: "已退款",
    105: "已退款",
    106: "已退款",
    107: "已退款",
    110: "已退款",
  };
  // 分页
  form = {
    cur_page: 1,
    page_size: 10,
  };

  totalSize = 0;
  activityState = ["筹备中", "进行中", "已完成"];
  created() {
    // this.requestList();
    this.requestList();
  }
  viewDetail(v: any) {
    // this.$router.push({
    //   path: "/onlineconsultroom/mypatient/detail",
    //   query: { id: v },
    // });
  }
  handleSizeChange(val: number) {
    console.log(`每页 ${val} 条`);
    // this.form.cur_page = 1;
    this.form.page_size = val;
    this.requestList();
  }

  handleCurrentChange(val: number) {
    console.log(`当前页: ${val}`);
    this.form.cur_page = val;
    this.requestList();
  }

  //重置
  searchReset() {
    this.formList = {
      start_createdate: "",
      end_createdate: "",
      patient_name: "",
      appointment_state: "",
    };
  }
  //EleMediRecord
  async requestList() {
    const form = { ...this.formList, ...this.form };
    const temp = {} as any;
    form.start_createdate = Date.parse(this.formList.start_createdate);
    form.end_createdate = Date.parse(this.formList.end_createdate) + 86400000; //多加一天毫秒数
    const Api: any = form; //重新定义了下循环的对象
    for (const key in Api) {
      if (Api[key]) {
        temp[key] = Api[key];
      }
    }
    const result = await appointmentList(temp);
    if (result.success) {
      this.tableData = result.data;
      // this.form.cur_page = 1;
      this.totalSize = result.page.total_size;
    } else {
      this.$message.success("搜索失败");
    }
  }

  searchButton() {
    this.form.cur_page = 1;
    this.requestList();
  }
}
